@media screen and (min-width: 700px) {
    .Article-BOT {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: auto;
        width: 50%;
        text-align: end;
    }

    .Article-BOT img {
        width: 20%;
        height: 20%;
    }
}

@media screen and (max-width: 700px) {
    .Article-BOT {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: end;
        width: 90%;
        padding: 5%;
    }
}