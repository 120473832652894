#mc-text {
  font-size: 65%;
  font-family: "Minecraft";
  color: yellow;
}

.Chamba-Div {
  padding: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.Chamba-Div img {
  height: 20%;
  width: 20%;
  padding: 30px;
}

.Generic-Row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.Generic-Column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App {
  background-color: #282c34;
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%234c18a0' fill-opacity='0.32' fill-rule='evenodd'/%3E%3C/svg%3E");
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

#footer_links {
  border-top: solid wheat 1px;
  margin-top: 5px;
  width: fit-content;
  margin: auto;
  align-self: center;
}

footer {
  margin-top: 4%;
  padding-top: 1%;
  background-color: #4f4f4f;
}

footer h5{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
}

footer img {
  width: 30px;
  margin-top: 5px;
  padding: 5px;
}

footer p {
  color: #d7d7d7;
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  display: flex;
  justify-content: center;
}

footer a {
  margin: auto;
  font-size: 55%;
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}