@import url("./Buttons.css");
@import url("./Privacy.css");
@import url("http://fonts.cdnfonts.com/css/minecraft-3");
@import url('https://fonts.cdnfonts.com/css/glukmixer');
@import url('https://fonts.cdnfonts.com/css/sonsie-one');
@import url('https://fonts.cdnfonts.com/css/excalibur');
@import url('https://fonts.cdnfonts.com/css/glix');
@import url('https://fonts.cdnfonts.com/css/house-on-mars');
@import url('https://fonts.cdnfonts.com/css/were-beast-2');
@import url('https://fonts.cdnfonts.com/css/assassin-nation');
@import url('https://fonts.cdnfonts.com/css/blokhed');
@import url('https://fonts.cdnfonts.com/css/discontinuo-tfb');
@import url('https://fonts.cdnfonts.com/css/white-on-black');
@import url('https://fonts.cdnfonts.com/css/foreshadow-brk');
@import url('https://fonts.cdnfonts.com/css/runelike');
@import url('https://fonts.cdnfonts.com/css/grunge-manifesto');
@import url('https://fonts.cdnfonts.com/css/dalek');
@import url('https://fonts.cdnfonts.com/css/lcd-7');
@import url('https://fonts.cdnfonts.com/css/sf-groove-machine-3');
@import url('https://fonts.cdnfonts.com/css/minecrafter-alt');
@import url('https://fonts.cdnfonts.com/css/roblox-logo-original');
@import url('https://fonts.cdnfonts.com/css/confessions');
@import url('https://fonts.cdnfonts.com/css/gradation');
@import url('https://fonts.cdnfonts.com/css/homoarakhn');
@import url('https://fonts.cdnfonts.com/css/breezedcaps');
@import url('https://fonts.cdnfonts.com/css/red-screen-eye');
@import url('https://fonts.cdnfonts.com/css/carbontype');
@import url('https://fonts.cdnfonts.com/css/led-panel-station-off');
@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Aguafina+Script&family=Astloch:wght@400;700&family=Bebas+Neue&family=Caveat:wght@400..700&family=Dancing+Script:wght@400..700&family=Jacquard+12&family=Permanent+Marker&family=Pirata+One&family=Sedan+SC&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: medium;
}

h2 {
  font-size: 150%;
  line-height: 30%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

ul {
  list-style-type: none;
}
