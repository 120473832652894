.PrivacyDiv a {
    color: #4f4ffb;
}

.PrivacyDiv p {
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 1%;
    margin: 0;
    text-align: justify;
}

.PrivacyDiv h2 {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 3%;
    text-align: center;
}

.PrivacyDiv h3 {
    padding-left: 8%;
    padding-right: 8%;
    text-align: left;
}